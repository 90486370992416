.login-wrap{
  svg{
    color:#fb8c22;
    font-size: 100px;
  }
  .brand-logo{
    img{
      width: 330px;
      padding: 10px;
    }
  }
  .thanku-word{
    .word{
      font-size: 30px;
      font-weight: bold;
    }
    .small-word{
      font-size: 18px;
    }
  }
  .back-to-home{
    font-weight: bold !important;
    font-size: 18px !important;
    padding: 15px !important;
  }
}