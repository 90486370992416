.breadcrumb-item {
    line-height: 32px;
}

.navbar {left:268px;}

.sidebar .sidebar-brand-wrapper .brand-logo-mini img {
    width: calc(70px - 32px);
    max-width: 100%;
    margin: auto;
}
 .sidebar .nav .brand_menu_container img.normal-logo{
    display: block;
}

 .sidebar .nav .brand_menu_container img.mini-logo{
    display: none;
}
.export-btn{
    font-size: 12px;
}
.summer-title{
    color:#fff;
     font-size:25px !important;
}
.csv-btn{
    float: right;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    color: #fff;
    background-color: #3f6cb2 !important;
    font-family: "Montserrat", sans-serif !important;
    box-shadow: none !important;
    font-size: 16px;
    border: none;
    padding: 6px 15px !important;
}

@media (min-width: 768px) and (max-width : 1024px) {
    .navbar {left:0px;}
    .MuiButton-containedSecondary, .MuiButton-containedPrimary{
        font-size: 12px !important;
        padding: 6px 15px !important;
    }
    .admin-navbar .img-xs {
        width: 42px;
        height: 42px;
    }
    .admin-sidebar {
        width: auto !important;
    }
    .summer-title{
         font-size:15px !important;
    }
    .export-btn{
        font-size: 9px;
    }
    .csv-btn{
        font-size: 14px;
        padding: 4px 15px !important;
    }
    

}
@media (min-width: 992px)
{ 
.sidebar-icon-only .sidebar .nav .brand_menu_container img.normal-logo{
    display: none;

}

.sidebar-icon-only .sidebar .nav .brand_menu_container img.mini-logo{
    overflow: visible;
    display: block;
    width: 50px;
    height: 50px;
}
.sidebar-icon-only .sidebar {
    width: 70px !important;
}


}

@media screen and (max-width: 991px){
    .sidebar-offcanvas {
        position: fixed;
        max-height: calc(100vh - 63px);
        top: 63px;
        bottom: 0;
        overflow: auto;
        right: -380px;
        transition: all 0.25s ease-out;
    }
}
.producer_search .MuiInputBase-input {
    background-color: #e5f3ff;
    border-radius: 4px 0 0 4px;
    margin-left: 10px;
    padding-left: 5px;
    color: #454545 !important;
}
.producer_search .MuiInputBase-input::-webkit-input-placeholder { 
    color: #272b46;
  }
  
  .producer_search .MuiInputBase-input:-ms-input-placeholder { 
    color: #272b46;
  }
  
  .producer_search .MuiInputBase-input::placeholder {
    color: #272b46;
  }
  .producer_search .MuiIconButton-root {
    background-color: #fb8c22 !important;
    border-radius: 0px 4px 4px 0 !important;
    padding: 15px !important;
  }
  .edit_search .MuiInputBase-input {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
}
 .selecterwrap legend {
    max-width: 0px !important;
}
.selecterwrap .MuiOutlinedInput-root,
.MuiFormControl-root
{
    width: 100%;
}

.selecterwrapbull .MuiFormControl-root{
    width: 100%;
}
.OrWrap{
    font-size: 14px;
    padding-top: 20px;
    font-weight: bold;
 }

 .no-margin{
     margin-left: 0px !important;
     margin-right: 0px !important;
 }
 .dialog-info{
     min-height: 380px;
 }

 .MuiFormControl-root label{
    margin-left: 0px !important;
 }
 .MuiTypography-body1{
     font-size:0.95rem !important;
 }

 .seman-select{
     width: 25%;
 }

 .sexdrive1{
     width: 40%;
 }
 .sexdrive1 legend {
    max-width: 0px !important;
 }

 .mtsearch .MuiTextField-root{
    width: 200px;
}
.mtsearch .MuiTableFooter-root{
    background-color: #272b46;
}
.mtsearch .MuiTableFooter-root .MuiTypography-caption{
    color: #fff;
}
.summery-search .MuiTablePagination-caption{
    color: #fff;
}
.MuiButton-label a{
    color: #fff;
}
.tablefooterrow{
    background-color: #272b46;
    border-radius: 6px;
    padding: 10px 24px 6px 16px !important;
    margin-top: 30px !important;
}
.tablefooter{
    width:23%;
    float:right;
}
.tablefooter .MuiFormControl-root{
    width: 30%;
    float: left;
    padding: 0px !important;
}
.tablefooter .MuiOutlinedInput-input {
    padding: 10px 14px;
    color: #fff !important;
    background-color: #272b46;
}
.tablefooter .MuiOutlinedInput-input option {
    background-color: #272b46 !important;
}
.tablefooter .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(5px, -6px) scale(0.75);
    color: #fff !important;
}
.tablefooter .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255,255, 255, 0.65);
}
.tablefooter .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.87) !important;
}
.tablefooter .MuiSelect-icon {
    color: rgba(255,255, 255, 0.87) ;
}
