// .btn-group {
//   border: $border-width solid $border-color;
//   border-radius: $btn-border-radius;

//   .btn {
//     border-top: none;
//     border-bottom: none;
//     border-left: none;

//     &:last-child {
//       border-right: none;
//     }

//     &.btn-primary {
//       border-color: darken(theme-color(primary), 3%);
//     }

//     &.btn-secondary {
//       border-color: darken(theme-color(secondary), 3%);
//     }

//     &.btn-info {
//       border-color: darken(theme-color(info), 3%);
//     }

//     &.btn-warning {
//       border-color: darken(theme-color(warning), 3%);
//     }

//     &.btn-success {
//       border-color: darken(theme-color(success), 3%);
//     }

//     &.btn-danger {
//       border-color: darken(theme-color(danger), 3%);
//     }

//     &.btn-dark {
//       border-color: darken(theme-color(dark), 3%);
//     }

//     &.btn-light {
//       border-color: darken(theme-color(light), 3%);
//     }
//   }
// }

.MuiIconButton-root{
  width: 25px;
  height: 26px;
}
.MuiIconButton-colorInherit{
  width:45px;
  height:45px;
}

.register-already{
  .alreadyLogin{
    margin: 0 auto;
  }
}
.auth form .form-group {
  margin-bottom: 1rem !important;
} 
